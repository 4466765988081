import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog, MatStepper } from '@angular/material';
import { CatalogSyndicationService } from 'src/app/role/super-admin/components/catalog-management/catalog-syndication.service';

enum PhaseStepType {
  // Explicitly use the indexes 0, 1, 2 below, so that each step matches with the index of
  // the MatStepper. This makes comparing a step with "this.stepper.selectedIndex" easier.
  TRIAL = 0,
  DISCOUNT = 1,
  REGULAR = 2,
}

@Component({
  selector: 'app-side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss']
})
export class SideModalComponent implements OnInit {

  @Input('show') show: boolean;
  @Input('planPhasesData') planPhasesData;
  @Input('phasesActionType') phasesActionType: string;
  @Output('close') DataOnClose: EventEmitter<any> = new EventEmitter<any>();
  constructor(private catalogService: CatalogSyndicationService, 
    private fb: FormBuilder, 
    private dialog: MatDialog, 
    public rootFormGroup: FormGroupDirective,
    private cdRef: ChangeDetectorRef) { }

  isShow: boolean;
  planPhasesForm: FormGroup;

  initialFormValue: any; 
  formChanged: boolean = false; 
  @ViewChild('stepper',{static: true}) stepper: MatStepper;
  public stepType = PhaseStepType;
  ngOnInit() {
    this.planPhasesForm = this.rootFormGroup.control.get('planPhasesForm') as FormGroup;
    this.initialFormValue = this.planPhasesForm.getRawValue();
    this.planPhasesForm.valueChanges.subscribe(() => {
      this.formChanged = !this.isFormUnchanged();
    });
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    if (this.planPhasesData.length <= 0) {
      Object.keys(this.planPhasesForm.value).forEach(res => {
        const type = this.planPhasesForm.value[res].type;
        if (type === "UNLIMITED_PHASE") {
          let arrayForm = this.planPhasesForm.get(res).get('recurringRegularPriceFormArray') as FormArray;
          arrayForm.controls.forEach((control: FormGroup) => {
            control.get('price').clearValidators();
            control.get('price').updateValueAndValidity();
          });
        }
      })
    }
  }

  private isFormUnchanged(): boolean {
    return JSON.stringify(this.initialFormValue) === JSON.stringify(this.planPhasesForm.getRawValue());
  }

  onRegularPhaseFormSlection(ev) {
    if(ev.value === "UNLIMITED_PHASE") {
      this.planPhasesForm.get('regular').get('duration').get('count').clearValidators();
    } else {
      this.planPhasesForm.get('regular').get('duration').get('count').setValidators([Validators.min(1)]);
    }
    this.planPhasesForm.get('regular').get('duration').get('count').updateValueAndValidity();
  }

  onTypeChange(ev) {
    let selectedOption = ev.value;
    let formName = this.getFormValue(selectedOption);
    if(selectedOption === "UNLIMITED_PHASE" || selectedOption === "skipTrail" || selectedOption === "skipDiscount") {
      this.planPhasesForm.get(formName).get('duration').get('count').clearValidators();
    } else {
      this.planPhasesForm.get(formName).get('duration').get('count').setValidators([Validators.min(1)]);
    }
    this.planPhasesForm.get(formName).get('duration').get('count').updateValueAndValidity();
  }
  
  getFormValue(key: string): string {
    switch (key) {
      case "TRIAL_PHASE":
        return 'freeTrail';
      case "skipTrail":
        return 'freeTrail';
      case "DISCOUNT_PHASE":
        return 'discount';
      case "skipDiscount":
        return 'discount';
      case "UNLIMITED_PHASE":
        return 'regular';
      case "FIXED_PHASE":
        return 'regular';
      default:
        return 'freeTrail';
    }
  }

  get recurringDiscountPriceFormArray() {
    return this.planPhasesForm.controls['discount'].get('recurringDiscountPriceFormArray') as FormArray;
  }

  get recurringRegularPriceFormArray() {
    return this.planPhasesForm.controls['regular'].get('recurringRegularPriceFormArray') as FormArray;
  }

  get trailPhaseType() {
    return this.planPhasesForm.get('freeTrail').get('type') as FormControl;
  }

  get trailPhaseForm() {
    return this.planPhasesForm.get('freeTrail') as FormGroup;
  }

  get discountForm() {
    return this.planPhasesForm.get('discount') as FormGroup;
  }

  get regularForm() {
    return this.planPhasesForm.get('regular') as FormGroup;
  }

  get discountPhaseType() {
    return this.planPhasesForm.get('discount').get('type') as FormControl;
  }

  get regularPhaseType() {
    return this.planPhasesForm.get('regular').get('type') as FormControl;
  }

  @ViewChild('exitConsentDialog', {static: true}) exitConsentDialog = {} as TemplateRef<any>;
  dialogRef;
  onClose() {
    if(this.formChanged) {
      this.dialogRef = this.dialog.open(this.exitConsentDialog, {disableClose: true, panelClass: 'discard-dialog-box'});
      this.dialogRef.afterClosed().subscribe(result => {
        if (result === "discard") {
          this.closeDialog();
        }
      })
    } else {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.DataOnClose.emit(this.planPhasesData);
  }

  onDiscardEditing() {
    this.discardChanges();
    this.dialogRef.close('discard');
  }

  updateChanges() {
    this.planPhasesData = this.formPlanPhasesData(this.planPhasesForm.getRawValue());
    this.initialFormValue = this.planPhasesForm.getRawValue();
    this.formChanged = false;
    this.catalogService.updateProductFormModified(this.formChanged);
  }

  saveChanges() {
    this.planPhasesData = this.formPlanPhasesData(this.planPhasesForm.getRawValue());
    this.initialFormValue = this.planPhasesForm.getRawValue();
    this.formChanged = false;
    this.catalogService.updateProductFormModified(this.formChanged);
  }

  discardChanges() {
    this.planPhasesForm.patchValue(this.initialFormValue);
    this.formChanged = false;
    this.catalogService.updateProductFormModified(this.formChanged);
  }

  showBillingPeriod(period: string): string {
    switch(period) {
      case 'MONTHLY':
        return 'month';
        break;
      case 'QUARTERLY':
        return 'quarter';
        break;
      case 'ANNUAL':
        return 'year';
        break;
      case 'TRIENNIAL':
        return '3 years';
        break;
      default:
        return 'month';
        break;
    }
  }

  formPlanPhasesData(formData) {
    let result = [];
    Object.keys(formData).forEach(res => {
      if (formData[res].type === "skipDiscount" || formData[res].type === "skipTrail") {
        return null;
      } else {
        let arrayData = formData[res]!.recurringRegularPriceFormArray || formData[res]!.recurringDiscountPriceFormArray;
        if (arrayData) {
          let recurringPrice = {};
          for (let priceData of arrayData) {
            recurringPrice[priceData.currency] = priceData.price;
          }
          formData[res] = {
            recurringPrice,
            ...formData[res]
          }
          delete formData[res]!.recurringRegularPriceFormArray;
          delete formData[res]!.recurringDiscountPriceFormArray;
          if (formData[res].type === "UNLIMITED_PHASE") {
            delete formData[res].duration;
          }
          result.push(formData[res]);
          return;
        } else {
          if (formData[res].type === "UNLIMITED_PHASE") {
            delete formData[res].duration;
          }
          result.push(formData[res]);
        }
      }
    });
    return result;
  }

  continue() {
    this.stepper.next();
  }

  previous() {
    this.stepper.previous();
  }

  isStepEditable(phaseStepType: PhaseStepType): boolean {
    const evaulatingOpenStep = this.stepper.selectedIndex === phaseStepType;
    const openFormIsValid = this.stepValid(this.stepper.selectedIndex);
    return evaulatingOpenStep || openFormIsValid;
  }

  stepValid(phaseStepType: PhaseStepType): boolean {
    switch (phaseStepType) {
      case PhaseStepType.TRIAL:
        return this.trailPhaseForm.valid;
      case PhaseStepType.DISCOUNT:
        return this.discountForm.valid;
      case PhaseStepType.REGULAR:
        return this.regularForm.valid;
      default:
        return true;
    }
  }

}

export interface UnlimitedPhase {
  type: string;
  recurringPrice: RecurringPrice;
}

export interface TrialPhase {
  type: string;
  duration: Duration;
}

export interface DiscountPhase {
  type: string;
  duration: Duration;
  recurringPrice: RecurringPrice;
}

export interface FixedPhase {
  type: string;
  duration: Duration;
  recurringPrice: RecurringPrice;
}

export type Duration = {
  unit: string;
  count: number;
}

export type RecurringPrice = {
  [currencyCode: string]: number;
}
