import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatIconModule, MatInputModule, MatSnackBarModule, MatTableModule } from '@angular/material';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './shared/http/interceptor.service';
import { SharedModule } from './shared/shared.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormatTimePipe, VerificationComponent } from './auth/verification/verification.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ErrorCatchingInterceptor } from './shared/http/error-catching.interceptor';
import { CdkTableModule } from '@angular/cdk/table';
import { HealthCheckComponent } from './health-check.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    VerificationComponent,
    FormatTimePipe,
    HealthCheckComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    CdkTableModule,
    MatTableModule,
    MatSnackBarModule,
    SharedModule,
    NgxMatSelectSearchModule,
    NgOtpInputModule,
    MatIconModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorCatchingInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
