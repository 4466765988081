import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ChildSync, ListProductsResponse, MetricsMapping, ProviderModel, paginationModel } from './product';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CatalogSyndicationService {
  isEditProductPage = new BehaviorSubject<any>(null);
  isEditProduct$ = this.isEditProductPage.asObservable();
  showLogReportSubject = new BehaviorSubject<{open: boolean, data: any}>({open: false, data: null});
  showLogReport$ = this.showLogReportSubject.asObservable();

  isProductFormModifiedSubject = new BehaviorSubject<any>(null);
  isProductFormModified$ = this.isProductFormModifiedSubject.asObservable();
  productInfo = new BehaviorSubject<any>({});
  stateService: any = {isNewAction:false, selectedEvent:  'DEPLOYMENT'};

  private _providerList: ProviderModel[];
  constructor(private http: HttpClient) { }

  downloadReport(trans_ID: string): Observable<any> {
    let request_url = `api/pc/syndication/sync_catalog/export?trans_id=cm-${trans_ID}`;
    return this.http.get(
      request_url, { responseType: 'text' }
    );
  }

  updateProductFormModified(data: boolean) {
    this.isProductFormModifiedSubject.next(data);
  }

  updateProuductInfoRef(product: any): void{
    this.productInfo.next(product);
  }

  updateShowLogReport(details: {open: boolean, data: any}) {
    this.showLogReportSubject.next(details);
  }

  getProductInfoRef(): Observable<any>{
    return this.productInfo;
  }


  getAccountsList() {
    let request_url = `/assets/json/accounts-vendors-list.json`;
    return this.http.get(
      request_url
    );
  }

  getSyncFromGolden(publisherName?: string) {
    let request_url = `api/pc/syndication/get_sync_from_golden`;
    let request_body = {
      "provider_id": 30,
      "trans_type": "gc"
    }
    return this.http.post(
      request_url,
      request_body
    );
  }

  getMyProducts(publisherName?: string) {
    let request_url = `api/pc/syndication/sync_catalog/my-products`;
    return this.http.get(
      request_url
    );
  }

  

  getImportedProductsList() {
    let request_url = `/assets/json/imported-product-list.json`;
    return this.http.get(
      request_url
    );
  }

  getFetchedData() {
    // let request_url = `getpricesheets(Market=%27US%27,PricesheetView=%27updatedlicensebased%27)/$value?timeline=history&month=20240505`;
    let request_url = `api/pc/syndication/get_sync_from_golden`;
    return this.http.get(
      request_url
    );
  }

  getProductInfo(productId: string, taskId: string) {
    let request_url = `api/pc/syndication/sync_catalog/get_items?task_id=${taskId}&product_id=${productId}`;
    //let request_url = `/assets/json/list-products.json`;
    return this.http.get(
      request_url
    );
  }

  getProgramsByProductID(account_id: number, productId?: string) {
    let request_url = `api/pc/syndication/accounts/${account_id}/products_programs/`;
    const params = new HttpParams();
    if(productId) {
      params.append('product_id', productId)
    }
    return this.http.get(
      request_url,
      {params: params}
    );
  }
  
  getSupportedCurrencies(account_id: number, provider_id?: number) {
    let request_url = `api/pc/syndication/accounts/${account_id}/cloudProviders/${provider_id}/currencies/`;
    return this.http.get(
      request_url
    );
  }

  getCharges(account_id?: number){
    let request_url = `api/pc/syndication/accounts/${account_id}/charges/`;
    return this.http.get(
      request_url
    );
  }

  getDiscount(account_id?: number){
    let request_url = `api/pc/syndication/accounts/${account_id}/discounts/`;
    return this.http.get(
      request_url
    );
  }

  getChargeAndDiscountById(name: string){
    let request_url = `api/pc/syndication/${name}`;
    return this.http.get(
      request_url
    );
  }

  createChargeAndDiscount(payload: any, name: string){
    let request_url =`api/pc/syndication/${name}`;
    return this.http.post(
      request_url,
      payload
    );
  }

  updateChargeAndDiscount(payload: any, name: string){
    let request_url =`api/pc/syndication/${name}`;
    return this.http.put(
      request_url,
      payload
    );
  }

  deleteChargeAndDiscount(name: string){
    let request_url =`api/pc/syndication/${name}`;
    return this.http.delete(
      request_url
    );
  }

  getSyncToGoldenAccountSteps() {
    return of({
      "data": [{ "state_id": 1, "name": "Review and Update" },
          { "state_id": 2, "name": "Staged Records" },
          { "state_id": 3, "name": "Publish" }],
      "status": 200
    })
  }

  getDisticntProduct(request_url){
    return this.http.get(
      request_url,
    );
  }

    newProduct(payload){
      let request_url =`api/pc/syndication/cbot-products/copy-configurations/`;
      return this.http.post(
        request_url,
        payload
      );
    }
    
    migrateProduct(payload){
      let request_url =`api/pc/syndication/cbot_products/migrate`;
      return this.http.post(
        request_url,
        payload
      );
  }

  getHistoryLists() {
    let request_url = `api/pc/syndication/sync_catalog_history`;
    return this.http.get(
      request_url
    );
  }

  onPublish(payload) {
    //let request_url = 'api/pc/syndication/publish_catalog';
    let request_url =`api/pc/syndication/publish-cbot-products`;
    return this.http.post(
      request_url,
      payload
    );
  }

  getProductList(taskId: string) {
    let request_url = `/assets/json/get-products-list.json`;
    let request_url_API = `/assets/json/list-products-backend.json`;
    let request_url_live = `api/pc/syndication/get_async_status/${taskId}`;
    return this.http.get(
      request_url_live
    );
  }

  getImportedProductList(paginatorObj): Observable<ListProductsResponse> {
    //let request_url = `/assets/json/get-products-list.json`;
    let request_url = `api/pc/syndication/cbot-products/`;
    let params = new HttpParams()
      .set('page',paginatorObj.pageIndex.toString())
      .set('page_size', paginatorObj.pageSize.toString())
      if (paginatorObj.sort) {
        params = params.append('ordering', paginatorObj.sort);
      }
      if(paginatorObj.filterValue) {
        params = params.append('search', paginatorObj.filterValue);
      }
      if(paginatorObj.showPublishedVersion) {
        params = params.append('exclude_new_product', "true");
        //params = params.append('is_published', paginatorObj.showPublishedVersion);
      }
      if(paginatorObj.filterFormData) {
        Object.keys(paginatorObj.filterFormData).forEach(data => {
          if(this.nullCheck(paginatorObj.filterFormData[data]) && data !== 'dateRange') {
            params = params.append(data, paginatorObj.filterFormData[data]);
          } 
        });
      }
      if(paginatorObj.chipsFilter) {
        const has_promotions = paginatorObj.chipsFilter === 'Promotions';
        if(has_promotions) params = params.append('has_promotions', String(has_promotions));
        // if(!has_promotions) params = params.append('sync_status', this.metricsMapping[paginatorObj.chipsFilter]);
      }
      return this.http.get<ListProductsResponse>(request_url, {params: params});
  }

  metricsMapping: MetricsMapping = {
    "Products Created": "New",
    "Products Updated": "Updated",
    "Products Skipped": "No Change",
    "Products Failed": "Failed",
    "Products Published": "Published"
  };

  getSyncedProductList(paginatorObj): Observable<ListProductsResponse> {
    // let request_url = `/assets/json/sync_produc_list.json`;
    let request_url = `api/pc/syndication/synced_product/list`;
    let params = new HttpParams()
      .set('page',paginatorObj.pageIndex.toString())
      .set('page_size', paginatorObj.pageSize.toString())
      if (paginatorObj.sort) {
        params = params.append('ordering', paginatorObj.sort);
      }
      if(paginatorObj.filterValue) {
        params = params.append('search', paginatorObj.filterValue);
      }
      if(paginatorObj.showPublishedVersion) {
        params = params.append('exclude_new_product', "true");
        //params = params.append('is_published', paginatorObj.showPublishedVersion);
      }
      if(paginatorObj.log_id) {
        params = params.append('log_id', paginatorObj.log_id);
      }
      if(paginatorObj.filterFormData) {
        Object.keys(paginatorObj.filterFormData).forEach(data => {
          if(this.nullCheck(paginatorObj.filterFormData[data]) && data !== 'dateRange') {
            params = params.append(data, paginatorObj.filterFormData[data]);
          } 
        });
      }
      if(paginatorObj.chipsFilter) {
        const has_promotions = paginatorObj.chipsFilter === 'Promotions';
        const has_published = this.metricsMapping[paginatorObj.chipsFilter] === 'Published';
        if(has_promotions) params = params.append('has_promotions', String(has_promotions));
        // if(has_published) params = params.append('product_status', this.metricsMapping[paginatorObj.chipsFilter]);
        // if(!has_promotions && !has_published) params = params.append('sync_status', this.metricsMapping[paginatorObj.chipsFilter]);
      }
      return this.http.get<ListProductsResponse>(request_url, {params: params});
  }

  nullCheck(data): boolean {
    if(Array.isArray(data)) {
      return data.length > 0;
    }
    if(!Array.isArray(data)) {
      return Boolean(data);
    }
  }

  getSyncedProductByID(id: number) {
    let request_url = `api/pc/syndication/synced_product/${id}/`;
    return this.http.get(request_url);
  }

  getImportedProductByID(id: number) {
    let request_url = `api/pc/syndication/cbot-products/${id}/`;
    return this.http.get(request_url);
  }

  getProductByID(id: number, type?: string ) {
    let request_url: string = type === "Synced" ? `api/pc/syndication/synced_product/${id}/` : `api/pc/syndication/cbot-products/${id}/`;
    return this.http.get(request_url);
  }

  updateProduct(productInfo, childSyncList: ChildSync[] = []) {
    //let request_url = `/assets/json/get-products-list.json`;
    const payload = {
        "productDetails": productInfo,
        "childSyncList": childSyncList
    }
    let request_url = `api/pc/syndication/update-cbot-products/${productInfo.id}/`;
    return this.http.put(
      request_url,
      payload
    );
  }

  getStagedProductList(paginatorParams) {
    let params = new HttpParams()
    .set('page', paginatorParams.pageIndex.toString())
    .set('page_size', paginatorParams.pageSize.toString())
    if(paginatorParams.sort) {
      params = params.append('ordering', paginatorParams.sort)
    }
    let request_url = `api/pc/syndication/cbot-products/?product_status=Staged`;
    return this.http.get(
      request_url, {params: params}
    );
  }

  unStageProducts(productPayload) {
    //let request_url = `/assets/json/get-products-list.json`;
    let request_url = `api/pc/syndication/sync_catalog/unstage`;
    return this.http.post(
      request_url,
      productPayload
    );
  }

  alterProductState(productPayload) {
    let request_url = `api/pc/syndication/sync_catalog/alter_product`;
    return this.http.put(
      request_url,
      productPayload
    );
  }

  getCloudProviders(account_id: number) {
    let request_url = `api/pc/syndication/accounts/${account_id}/cloudProviders/?includeDisabled=false&priceBookEnabled=true`;
    // let request_url = `/assets/json/get-cloud-providers.json`;
    return this.http.get(
      request_url
    );
  }

  getProductAdditionalInfo() {
    let request_url = `/assets/json/get-product-additional-info.json`;
    return this.http.get(
      request_url
    );
  }
  
  getCategoriesForAccount(account_id: number) {
    let request_url = `api/pc/syndication/accounts/${account_id}/products/categories/`;
    return this.http.get(
      request_url
    );
  }
  
  getVerticalsForAccount(account_id: number) {
    let request_url = `/api/pc/syndication/accounts/${account_id}/products/verticals/`;
    return this.http.get(
      request_url
    );
  }
  
  getTagsForAccount(account_id: number) {
    let request_url = `api/pc/syndication/accounts/${account_id}/product/tags/`;
    return this.http.get(
      request_url
    );
  }

  getCurrencies() {
    let request_url = `api/pc/syndication/currencies/`;
    return this.http.get(
      request_url
    );
  }

  uploadImage(fileData, productId ): Observable<any> {
    const formData = new FormData();
    formData.append('image_file', fileData);
    formData.append('product_id', productId);
    formData.append('user_id',JSON.parse(sessionStorage.getItem('userInfo')).user_id)
    let request_url = 'api/pc/syndication/sync_catalog/blob_upload';
    return this.http.put(request_url,formData);
  }

  getProviderList() {
    let request_url = `api/pc/syndication/get/cloudProviders`;
    return this.http.get(
      request_url
    ).pipe(
      tap((res: ProviderModel[]) => {
        this.providerList = res;
      }),
    );
  }

  private isSettingProviderList: boolean = false;
  set providerList(data: ProviderModel[]) {
    if(this.isSettingProviderList) return;
    this.isSettingProviderList = true;
    this._providerList = data;
  }

  get providerList() {
    return this._providerList;
  }

  addNewProvider(payload): Observable<any> {
    const formData = new FormData();
    formData.append('ion_id', payload.ion_id);
    formData.append('ion_name', payload.ion_name);
    formData.append('display_name', payload.display_name);
    formData.append('logo', payload.logo);
    formData.append('user_id',JSON.parse(sessionStorage.getItem('userInfo')).user_id);
    let request_url = 'api/pc/syndication/cloudProviders';
    return this.http.post(request_url,formData);
  }

  getSegmentList(id: number): Observable<any> {
    let request_url = `api/pc/syndication/get/segments?provider__ion_id=${id}`;
    return this.http.get(request_url);
  }

  getRegionList(): Observable<any> {
    let request_url = `api/pc/syndication/get/regions?has_credentials=true`;
    return this.http.get(request_url);
  }

  getAccountList(market_id: number): Observable<any> {
    let request_url = `api/pc/syndication/accounts?market_id=${market_id}`;
    return this.http.get(request_url);
  }

  getCBOTCategories() {
    let request_url = `api/pc/syndication/product_categories`;
    return this.http.get(request_url);
  }

  getProvisioningData(category_id: string, account_id: number, product_id: string) {
    let request_url = `api/pc/syndication/product_templates/category/${category_id}/?account_id=${account_id}&product_id=${product_id}`;
    return this.http.get(request_url);
  }

  createCatalogJob(payload): Observable<any> {
    let request_url = `/api/pc/syndication/trigger/sync_job`;
    return this.http.post(
      request_url, 
      payload
    );
  }

  getCatalogJobs(type?: string, filterParams?: any) {
    let params = new HttpParams()
    .set('type', type);
    if(filterParams && filterParams.regions) {
      params = params.append('regions', filterParams.regions.toString());
    }
    if(filterParams && filterParams.frequencies) {
      params = params.append('frequencies', filterParams.frequencies.toString());
    }
    if(filterParams && filterParams.status) {
      params = params.append('status', filterParams.status.toString());
    }
    let request_url = `/api/pc/syndication/import-jobs`;
    return this.http.get(
      request_url, {params: params}
    );
  }

  disableCatalogJob(jobId: number, payload: any): Observable<any> {
    let request_url = `/api/pc/syndication/import-jobs/${jobId}/`;
    return this.http.patch(request_url , payload);
  }

  triggerJobRun(jobId: number): Observable<any> {
    let request_url = `/api/pc/syndication/vendor_sync/trigger/`;
    const payload = {
      "job_id": jobId
    }
    return this.http.post(request_url , payload);
  }

  triggerSyncJobRun(jobId: number, providerId: number, accountId: number): Observable<any> {
    let request_url = `api/pc/syndication/cbot_to_ccp_sync/trigger?account_id=${accountId}&job_id=${jobId}&provider_id=${providerId}`;
    return this.http.get(request_url);
  }

  createOrUpdateImportJob(payload: CreateImportJobPayload, operationType: 'add' | 'edit', jobId?: number) {
    let request_url = operationType === 'edit' ? `/api/pc/syndication/import-jobs/${jobId}/` : `/api/pc/syndication/import-jobs`;
    if(operationType === 'edit') {
      return this.http.put(
        request_url, 
        payload
      );
    }
    return this.http.post(
      request_url, 
      payload
    );
  }

  getCatalogJobLogs(jobId: number): Observable<any> {
    let request_url = `api/pc/syndication/import-jobs/${jobId}/`;
    return this.http.get(request_url);
  }

  getLatestJobLogMetrics(jobId: number): Observable<any> {
    let request_url = `api/pc/syndication/import-jobs/${jobId}/latest_log/metrics/`
    return this.http.get(request_url);
  }

  getImportJobsLogsStats(log_id: number):Observable<any> {
    let request_url = `api/pc/syndication/import-jobs/logs/${log_id}/metrics/`;
    return this.http.get(request_url);
  }

  getTranslationList(product_name: string, account_id: number) {
    let request_url = `api/pc/syndication/accounts/${account_id}/products/${product_name}/translations/list/`;
    // let request_url = `/assets/json/product-translation-list.json`;
    return this.http.get(
      request_url
    );
  }
  
  exportTranslation(product_name: string, account_id: number, languageCode: string) {
    let request_url = `api/pc/syndication/accounts/${account_id}/products/${product_name}/translations/export/?language=${languageCode}`;
    return this.http.get(
      request_url
    );
  }

  publishImportedProducts(productPayload) {
    let request_url = `api/pc/syndication/cbot_products/publish`;
    return this.http.post(
      request_url,
      productPayload
    );
  }

  publishSyncedProducts(productPayload) {
    let request_url = `api/pc/syndication/synced_product/publish`;
    return this.http.post(
      request_url,
      productPayload
    );
  }

  getImportJobLogDetail(job_id: number, log_id: number, filter?: string) {
    let params = new HttpParams();
    if(filter && filter !== 'total') {
      params = params.append('import_status', filter);
    }
    let request_url = `api/pc/syndication/import-jobs/${job_id}/logs/${log_id}`;
    return this.http.get(
      request_url, {params: params}
    );
  }

  exportJobLogReport(job_id: number, log_id: number){
    let request_url = `api/pc/syndication/import-jobs/report_export/${log_id}`;
    return this.http.get(request_url, { responseType: 'text' });
  }

  /*  Deployment Related API Methods Start Here  */

  getProcessCategories(accountId: number): Observable<any> {
    let request_url = `/api/pc/syndication/accounts/${accountId}/process/categories/`;
    return this.http.get(request_url);
  }

  getTypeByCategories(accountId: number, categorie: string): Observable<any> {
    let request_url = `/api/pc/syndication/accounts/${accountId}/process/${categorie}/types/`;
    return this.http.get(request_url);
  }

  getNameByCategoriesByType(accountId: number, categorie: string, config: string): Observable<any> {
    let request_url = `/api/pc/syndication/accounts/${accountId}/processes/?category=${categorie}&type=${config}`;
    return this.http.get(request_url);
  }

  getParameterByName(accountId: number, name: string): Observable<any> {
    let request_url = `/api/pc/syndication/accounts/${accountId}/process/${name}`;
    return this.http.get(request_url);
  }

  getProviderCredential(accountId: number):Observable<any> {
    let request_url = `/api/pc/syndication/accounts/${accountId}/providerCredentials/?includeToken=False`;
    return this.http.get(request_url);
  }
  /*  Deployment Related API Methods End Here  */

  convertToStagedRecord(product, publishID, stageId) {
    let productClone = JSON.parse(JSON.stringify(product));
    Object.keys(productClone).forEach(key => {
      if(key === "id") productClone["id"] = stageId;
      if(key === "has_unpublished_changes") productClone["has_unpublished_changes"] = false;
      if(key === "publish_id") productClone["publish_id"] = publishID;
      if(key === "draft_id") productClone["draft_id"] = null;
      if(key === "is_published") productClone["is_published"] = false;
      if(key === "product_status") productClone["product_status"] = "Staged";
    }); 
    return productClone;
  }

  getCatalogAccountList() {
    let request_url = "/api/pc/syndication/accounts";
    return this.http.get(request_url);
  }

  transformTermDuration(duration: string) {
    switch(duration) {
      case 'P1M':
        return '1-Month';
      case 'P1Y':
        return '12-Months';
      case 'P2Y':
        return '2-Years';
      case 'P3Y':
        return '3-Years';
      case 'PU':
        return 'Unlimited';
      default:
        return '';
    }
  }

  getChargesForSupportPlan(account_id: number) {
    let request_url = `/api/pc/syndication/accounts/${account_id}/charges/`;
    return this.http.get(request_url);
  }

  getProgramsList() {
    let request_url = `/assets/json/get-programs.json`;
    return this.http.get(
      request_url
    );
  }

  getProvisioning() {
    let request_url = `/assets/json/get-provisioning.json`;
    return this.http.get(
      request_url
    );
  }

  saveAndPublishNewProduct(payload: any) {
    let request_url = `api/pc/syndication/cbot_product/create/`;
    return this.http.post(
      request_url,
      payload
    );
  }
}

export type CreateImportJobPayload = {
  type: string;
  frequency: 'daily' | 'weekly' | 'monthly',
  provider: number;
  market_code: string;
  account: string;
  day_of_week: string | number | null;
  day_of_month: string | number | null;
}

export interface ImportJobLogDetail {
  job_id: number;
  market_code: string;
  market_name: string;
  latest_metrics: Statistics;
  import_modal: getImportJobsLogs;
  logs: ImportJobLog[];
}

export interface getImportJobsLogs {
  total: number
  imported: number
  added: number
  updated: number
  failed: number
  skipped: number
}

export interface ImportJobLog {
  id: number;
  created_at: string;
  completed_at: string;
  bucket_url: string;
  status: string;
}

export interface Statistics {
  added: number;
  failed: number;
  mapped: number;
  read: number;
  skipped: number;
}