import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { SuperAdminService } from 'src/app/role/super-admin/super-admin.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() openPartnerCompleteOrTerminateDialog: EventEmitter<any> = new EventEmitter();
  @Output() openDiscardConsentDialog: EventEmitter<any> = new EventEmitter();
  @Output() showHideMenu: EventEmitter<any> = new EventEmitter();
  @ViewChild('showBtn', {static: true}) showBtn: ElementRef;
  @ViewChild('cardModal', {static: true}) cardModal?: ElementRef;
  @ViewChild('notificationModal', {static: true}) notificationModal?: ElementRef;
  @ViewChild('notificationBtn', {static: true}) notificationBtn?: ElementRef;
  showCompleteOrTerminateToast: any;
  disableSection: boolean
  userInfo;
  showcard: boolean = false;
  @Input() isShowMenu: boolean;
  showDiscardDialog: boolean;
  showNotification: boolean = false;
  count: number;
  
  constructor(private authService: AuthService, private renderer: Renderer2, private superAdminService: SuperAdminService) { 
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(e.target !== (this.showBtn && this.showBtn.nativeElement) && e.target !== (this.cardModal && this.cardModal.nativeElement)){
          this.showcard=false;
      } 
      if(e.target !== (this.notificationBtn && this.notificationBtn.nativeElement) && e.target !== (this.notificationModal && this.notificationModal.nativeElement)){
        this.showNotification=false;
    } 
    });
  }

  ngOnInit() {
    this.authService.isLogin$.subscribe((res: any)=>{
      if (res) {
         this.authService.userInfo$.subscribe((user)=> {
          this.userInfo = user;
        })
      } else {
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      }
    })
    this.superAdminService.disableSections$.subscribe(res => {
      this.disableSection = res;
    })
    this.superAdminService.showPartnerUploadStatusDialog$.subscribe((res: any) => {
      this.showCompleteOrTerminateToast = res.partner_upload_status;
    })
    this.superAdminService.showDisacardConsentDialog$.subscribe(res => {
      this.showDiscardDialog = res;
    });
    this.getNotificationCount();
  }

  showHideUsercard(ev) {
    if(this.disableSection) {
      if (this.showCompleteOrTerminateToast) {
        this.openPartnerCompleteOrTerminateDialog.emit();
      }
    } else if(this.showDiscardDialog) {
      this.openDiscardConsentDialog.emit();
    } else {
      ev.stopPropagation();
      this.showcard = !this.showcard;
    }
  }

  openHideMenu() {
    this.showHideMenu.emit();
  }

  logout() {
    this.authService.logout();
  }

  openNotification(ev) {
    ev.stopPropagation();
    this.showNotification = !this.showNotification;
  }

  getNotificationCount() {
    this.superAdminService.getNotifications(1, 5).subscribe((res: any) => {
      const val = res.user_unread_count;
      this.count = val === 0 ? null : val;
    });
  }

  updateCount(val) {
    this.getNotificationCount();
  }
}
