import { Component, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, OnDestroy {
  verificationForm: FormGroup;
  userID ;
  email;
  maxLength = 6;
  countDown$: Subscription;
  secondsLeft;
  redirectUrl: string;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar) { 
      const navigation = this.router.getCurrentNavigation();
      this.redirectUrl = navigation!.extras!.state!.redirectUrl || '/uploads/partner-upload';
    }
    
  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.userID = JSON.parse(sessionStorage.getItem('userInfo')).user_id;
      this.email = JSON.parse(sessionStorage.getItem('userInfo')).email;
      this.verificationForm = this.fb.group({
        userIDControl: [this.userID ,[Validators.required]],
        verificationCodeControl: ['',Validators.required]
     })
     this.enableCountdown(5);
    } else {
      this.router.navigate(['login'])
    }
  }

  enableCountdown(mins) {
    this.countDown$ = timer(0, 1000).pipe(
      take(mins * 60),
      map(secondsElapsed => ((mins * 60)-1) - secondsElapsed)
    ).subscribe(secondsLeft => {
      this.secondsLeft = secondsLeft;
    });
  }

  onResend() {
    this.authService.resendVerification(this.userID).subscribe(response => {
      let res: any = response;
      if(res.message == 'Success') {
       this.openSnackBar("Login OTP Sent Successfully",'success');
       this.enableCountdown(5);
      } 
    },
    error => {
      this.openSnackBar(error.error.message,'error');
    })
  }

  onOtpChange(value: string) {
    if(value.length >= this.maxLength) {
      this.verificationForm.controls.verificationCodeControl.setValue(value);
    } else {
      this.verificationForm.controls.verificationCodeControl.setValue(null);
    }
    
  }

  onVerification() {
    if (this.verificationForm.valid) {
      this.authService.onVerification(this.verificationForm.value).subscribe(response => {
        let res: any = response;
        if(res.message == 'Success' && res.userInfo.authorization.length) {
          this.router.navigate([this.redirectUrl]);
        } else if(res.userInfo.authorization.length <=0) {
          this.openSnackBar("Access Denied: Not authorized to use this portal. Please contact the CBOT support team for assistance.",'error');
          this.router.navigate(['/login']);
        }
      },
      error => {
        this.openSnackBar(error.error.message,'error');
      })
    }
  }

  openSnackBar(message: string, type: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: `snack-${type}`,
      duration: 10000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      data: { 
        message: message,
        snackType: type
      }
    })
  }

  ngOnDestroy() {
    this.countDown$ = null;
  }
}

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}