import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  token: string;

  constructor(private loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show();
    let requestUpdated;
    const userID = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).user_id : null;
    if(req.url.includes('/assets/')) {
      requestUpdated = req.clone({ url: `${req.url}`,headers: new HttpHeaders({ 'Content-Type': 'application/json'}) });
    } else if (req.url.includes('api/pricebook/bulkupdate/upload-pricebook') 
    || req.url.includes('api/pricebook/bulkupdate/upload-reseller-cloudaccounts') 
    || req.url.includes('api/pricebook/bulkupdate/upload-reseller-cf')
    || req.url.includes('api/pc/syndication/sync_catalog/blob_upload')
    || req.url.includes('api/pc/syndication/cloudProviders')){
      requestUpdated = req.clone({ url: `${environment.baseURL}${req.url}`, headers: new HttpHeaders({ 'encrypt': 'multipart/form-data', 'X-User-Id': String(userID) }) });
    } else if(userID !== null) {
      requestUpdated = req.clone({ url: `${environment.baseURL}${req.url}`, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-User-Id': String(userID) }) });
    } else {
      requestUpdated = req.clone({ url: `${environment.baseURL}${req.url}`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }
    if (
      req.url.includes('api/master/get-partner-migration/status/') || 
      req.url.includes('api/master/partner-migration') || 
      req.url.includes('api/master/retry-partnerOnboarding') ||
      req.url.includes('api/master/validate/migration') ||
      req.url.includes('api/master/create-user') ||
      req.url.includes('api/master/get-customer-migration/status') ||
      req.url.includes('api/master/validate/usermigration') || 
      req.url.includes('/cloudProviders') || 
      req.url.includes('/cbot-product-names') || 
      req.url.includes('api/pc/syndication/cbot_to_ccp_sync/trigger') || 
      req.url.includes('api/notifications/manage_user_alerts') || 
      (req.url.includes('api/pricebook/bulkupdate') && 
        !(req.url.includes('api/pricebook/bulkupdate/activity-monitor') || 
          req.url === 'api/pricebook/bulkupdate/cf-progress-staging' || 
          req.url === 'api/pricebook/bulkupdate/progress-staging' || 
          req.url === 'api/pricebook/bulkupdate/reseller-progress-staging')
        )
      ){
      this.loaderService.hide();
    }
    
    return next.handle(requestUpdated).pipe(
      finalize(() => this.loaderService.hide()),
    );
  }
}